import { useEffect, useState } from "react";

const useClubDeadline = (
  managerMode: number,
  devPort: string,
  clubId: string,
  gameDate: string
) => {
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);
  const [deadline, setDeadline] = useState<Date>();
  console.log("game Date", gameDate);
  useEffect(() => {
    const club_deadline_fetch = devPort + "club_signup_deadline/" + clubId;
    console.log(club_deadline_fetch);
    fetch(club_deadline_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data) {
          console.log(data[0][0]);
          console.log(gameDate);
          const parsedGameDate = new Date(
            Date.parse(gameDate.replace(/(\d+)(st|nd|rd|th)/, "$1"))
          );
          const deadlineDate = new Date(parsedGameDate);
          deadlineDate.setHours(deadlineDate.getHours() - data[0][0]);
          const currentTime = new Date();
          console.log("current time", currentTime);
          console.log(deadlineDate);
          console.log(currentTime >= deadlineDate);
          setIsDeadlinePassed(
            managerMode !== -1 && currentTime >= deadlineDate
          );
          setDeadline(deadlineDate);
        }
      });
  }, [devPort, clubId, managerMode, gameDate]);

  return { isDeadlinePassed, deadline };
};

export default useClubDeadline;
