import { Card, CardContent, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

import useClubDeadline from "./useClubDeadline";
import chukkerbookTheme from "src/chukkerbbokTheme";
function DeadlineBanner({
  managerMode,
  devPort,
  clubId,
  gameDate,
}: {
  managerMode: number;
  devPort: string;
  clubId: string;
  gameDate: string;
}) {
  const { isDeadlinePassed, deadline } = useClubDeadline(
    managerMode,
    devPort,
    clubId,
    gameDate
  );
  return (
    <Card
      variant="outlined"
      data-testid="deadline-banner"
      style={{
        backgroundColor: isDeadlinePassed
          ? chukkerbookTheme["secondary"]
          : chukkerbookTheme["primary"],
        color: "white",
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            {isDeadlinePassed && <WarningIcon />}
            {!isDeadlinePassed && <InfoIcon />}
            <Typography>
              {isDeadlinePassed
                ? "The signup deadline was " +
                  deadline +
                  ". Please contact your manager"
                : "Please signup before " + deadline}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default DeadlineBanner;
