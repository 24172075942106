import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  LinearProgress,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useCallback, useEffect, useState } from "react";
import ProBox from "../Signups/ProBox";
import UserList from "../AcceptDenyList/UserList";
import getStripeConnectAccessList from "./getStripeConnectCoaches";
import SnackSuccess from "../Signups/SnackSuccess";
import SnackError from "../Signups/SnackError";
import { DatePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";

import dayjs from "dayjs";
import getWaiverLinks from "./getWaiverLinks";
import SubscriptionTable from "../Subscription/SubscriptionTable.tsx";
import ExpiredMembershipBanners from "../membership/ExpiredMembershipBanners";

function ClubSettingsCard({
  devPort,
  clubId,
  clubName,
  setSubscriptionMode,
  setClubId,
}) {
  const [restrictedAccess, setRestrictedAccess] = useState();
  const [waiverRenewalDate, setWaiverRenewalDate] = useState();
  const [loadingAccess, setLoadingAccess] = useState(true);
  const [loadingRenewalData, setLoadingRenewalData] = useState(true);
  const [settingsUpdate, setSettingsUpdate] = useState(false);
  const [settingsParams, setSettingsParams] = useState({
    privateMode: false,
    waiverRenewal: false,
    waiverLinks: false,
  });
  const [refresh, setRefresh] = useState(false);
  const [stripeConnectUsers, setStripeConnectUsers] = useState([]);
  //const [allowedStripeConnect, setAllowedStripeConnect] = useState(false);
  const [waiverLinks, setWaiverLinks] = useState([]);
  const [dbMailingList, setDBMailingList] = useState("");
  const [mailingList, setMailingList] = useState("");
  const [dbSignupDeadline, setDBSignupDeadline] = useState(0);
  const [signupDeadline, setSignupDeadline] = useState(0);
  function refresher() {
    setRefresh(!refresh);
  }
  const getAccessStatus = useCallback(() => {
    const fetch_club_access = devPort + "club_access/" + clubId;
    console.log(fetch_club_access);
    fetch(fetch_club_access)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Club Access");
        if (data) {
          setRestrictedAccess(data[0][0]);
        }
        console.log(data);
        setLoadingAccess(false);
      });
  }, [devPort, clubId]);

  function changeAccessStatus() {
    const fetch_club_access = devPort + "change_club_access";
    const change_access_mode = {
      club_id: clubId,
      access_mode: restrictedAccess,
    };
    console.log(change_access_mode);
    fetch(fetch_club_access, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_access_mode),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Club Access");
        return data["message"];
        /*if (data && data["message"].match("Successful")) {
          console.log(data["message"]);
          setSettingsUpdate(false);
          setAlertMessageSuccess(true);
        } else {
          setAlertMessageError(true);
        }*/
      });
  }

  const getWaiverRenewalDate = useCallback(() => {
    const fetch_waiver_renewal_date =
      devPort + "club_waiver_renewal_date/" + clubId;
    console.log(fetch_waiver_renewal_date);
    fetch(fetch_waiver_renewal_date)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Waiver Renewal DATE");
        if (data && data[0]) {
          console.log(data[0][0]);
          const waiver_date = moment(data[0][0]).utc().format("MM-DD");
          const current_year = moment().utc().format("YYYY");
          const next_year = (parseInt(current_year) + 1).toString();
          let newWaiverRenewalDate = dayjs(current_year + "-" + waiver_date);
          if (
            dayjs(moment().utc().format("YYYY-MM-DD")) >= newWaiverRenewalDate
          ) {
            newWaiverRenewalDate = dayjs(next_year + "-" + waiver_date);
          }
          console.log(newWaiverRenewalDate);
          setWaiverRenewalDate(newWaiverRenewalDate);

          //  setRestrictedAccess(data[0][0]);
        }
        console.log(data);
        setLoadingRenewalData(false);
      });
  }, [devPort, clubId]);

  function changeWaiverRenewalDate() {
    const fetch_club_waiver_renewal = devPort + "change_club_renewal_date";
    const change_waiver_renewal_date = {
      club_id: clubId,
      renewal_date: "1970-" + waiverRenewalDate.format("MM-DD"),
    };
    console.log(change_waiver_renewal_date);
    fetch(fetch_club_waiver_renewal, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_waiver_renewal_date),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Waiver Renewal");
        return data["message"];
        /*if (data && data["message"].match("Successful")) {
          console.log(data["message"]);
          setSettingsUpdate(false);
          setAlertMessageSuccess(true);
        } else {
          setAlertMessageError(true);
        }*/
      });
  }

  function changeWaiverLinks() {
    const fetch_waiver_links = devPort + "change_club_waiver_links";
    const change_waiver_links = {
      club_id: clubId,
      waiver_links: waiverLinks,
    };
    console.log(change_waiver_links);
    fetch(fetch_waiver_links, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_waiver_links),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Club Access");
        return data["message"];
        /*if (data && data["message"].match("Successful")) {
          console.log(data["message"]);
          setSettingsUpdate(false);
          setAlertMessageSuccess(true);
        } else {
          setAlertMessageError(true);
        }*/
      });
  }

  const getMailingList = useCallback(() => {
    const fetch_mailing_list = devPort + "club_mailing_list/" + clubId;
    console.log(fetch_mailing_list);
    fetch(fetch_mailing_list)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Mailing List");
        if (data && data[0]) {
          console.log(data[0][0]);
          setDBMailingList(data[0][0]);
          setMailingList(data[0][0]);
        }
        console.log(data);
        setLoadingRenewalData(false);
      });
  }, [devPort, clubId]);

  function changeMailingList() {
    const fetch_mailing_list = devPort + "change_club_mailing_list";
    const change_mailing_list = {
      club_id: clubId,
      mailing_list: mailingList,
    };
    console.log(change_mailing_list);
    fetch(fetch_mailing_list, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_mailing_list),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Club Access");
        refresher();
        return data["message"];
      });
  }

  const getSignupDedline = useCallback(() => {
    const fetch_signup_deadline = devPort + "club_signup_deadline/" + clubId;
    console.log(fetch_signup_deadline);
    fetch(fetch_signup_deadline)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Signup Deadline");
        if (data && data[0]) {
          console.log(data[0][0]);
          setDBSignupDeadline(data[0][0]);
          setSignupDeadline(data[0][0]);
        }
        console.log(data);
        setLoadingRenewalData(false);
      });
  }, [devPort, clubId]);

  function changeSignupDeadline() {
    const fetch_change_signup_deadline =
      devPort + "change_club_signup_deadline";
    if (signupDeadline < 0) {
      window.alert("Please enter a valid number of hours");
      return;
    }
    const change_signup_deadline = {
      club_id: clubId,
      signup_deadline: signupDeadline,
    };
    console.log(change_signup_deadline);
    fetch(fetch_change_signup_deadline, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_signup_deadline),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Club Deadline Changed");
        refresher();
        return data["message"];
      });
  }

  function changeProStipeConnectAccess(allowedStripeConnect) {
    if (!idPro) {
      window.alert("Select a Coach from the dropdown menu");
    } else {
      const fetch_stripe_access = devPort + "edit_player_connect";
      const change_stripe_access = {
        club_id: clubId,
        player_id: idPro,
        allowed_stripe_connect: allowedStripeConnect,
      };
      console.log(fetch_stripe_access);
      fetch(fetch_stripe_access, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify(change_stripe_access),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Stripe Access");
          console.log(data);
          setRegisterModal(false);
          refresher();
        });
    }
  }

  const [expiredSubscription, setExpiredSubscription] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  useEffect(() => {
    if (clubId !== -1) {
      console.log("Checking restrictions...");
      const subscription_fetch = devPort + "get_club_subscription/" + clubId;
      fetch(subscription_fetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data && "id" in data) {
            console.log("Subscription");
            console.log(data);
            if (
              "subscription_end_date" in data &&
              data["subscription_end_date"]
            ) {
              setSubscriptionEndDate(data["subscription_end_date"]);
            } else {
              setSubscriptionEndDate(null);
            }
            if (
              "subscription_end_date" in data &&
              data["subscription_end_date"] &&
              new Date(data["subscription_end_date"]).toISOString() <
                new Date().toISOString()
            ) {
              console.log("Subscription has ended");
              setExpiredSubscription(true);
            } else {
              console.log("Subscription is active");
              console.log(data["subscription_end_date"]);
              console.log(new Date().toISOString().split("T")[0]);
              setExpiredSubscription(false);
            }
          } else {
            console.log("Would you like to subscribe");
            console.log(data);
            setSubscriptionEndDate(null);
            setExpiredSubscription(false);
          }
        });
    }
  }, [clubId, devPort]);

  useEffect(() => {
    getAccessStatus();
    getWaiverRenewalDate();
    getWaiverLinks(devPort, clubId, setWaiverLinks);
    getStripeConnectAccessList(devPort, clubId, setStripeConnectUsers);
    getMailingList();
    getSignupDedline();
  }, [
    devPort,
    clubId,
    refresh,
    getAccessStatus,
    getWaiverRenewalDate,
    getMailingList,
    getSignupDedline,
  ]);
  console.log(restrictedAccess);
  console.log(loadingAccess);
  const [registerModal, setRegisterModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper", // 'red', //'background.paper',
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [idPro, setIdPro] = useState();
  const [alertMessageSuccess, setAlertMessageSuccess] = useState(false);
  const [alertMessageError, setAlertMessageError] = useState(false);
  /*function reportUsage() {
    const fetch_club_usage = devPort + "report-usage/" + clubId;
    console.log(fetch_club_usage);
    fetch(fetch_club_usage)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  }*/
  function updateApplyButton(settingType) {
    let newSettingsParams = { ...settingsParams };
    newSettingsParams[settingType] = true;
    setSettingsParams(newSettingsParams);
    setSettingsUpdate(true);
  }
  function updateWaiverLink(index, newValue, nameOrUrl) {
    const newWaiverLinks = [...waiverLinks];
    newWaiverLinks[index][nameOrUrl] = newValue;
    setWaiverLinks(newWaiverLinks);
    updateApplyButton("waiverLinks");
  }
  function deleteWaiverLink(index) {
    console.log("HERE");
    const newWaiverLinks = [...waiverLinks];
    console.log(index);
    newWaiverLinks.splice(index, 1);
    console.log(newWaiverLinks);
    setWaiverLinks(newWaiverLinks);
    updateApplyButton("waiverLinks");
  }

  function applyNewSettings() {
    if (settingsParams["waiverRenewal"]) {
      changeWaiverRenewalDate();
    }
    if (settingsParams["privateMode"]) {
      changeAccessStatus();
    }
    if (settingsParams["waiverLinks"]) {
      changeWaiverLinks();
    }
    setSettingsUpdate(false);
  }
  if (loadingAccess || loadingRenewalData) {
    return <LinearProgress />;
  }
  return (
    <Paper elevation={24}>
      <Card variant="elevation" data-testid={"club-setting-card-" + clubName}>
        <SnackSuccess
          alterMessage={alertMessageSuccess}
          setAlertMessage={setAlertMessageSuccess}
          snackMessage="Your changes have been applied"
        />
        <SnackError
          alterMessage={alertMessageError}
          setAlertMessage={setAlertMessageError}
          snackMessage="There was an issue updating the permissions. Please try again"
        />
        <CardContent>
          <Modal
            data-testid="add-coach-connect"
            open={registerModal}
            onClose={() => {
              setRegisterModal(false);
            }}
            aria-labelledby="Allow Coach Stripe Connect"
            aria-describedby="Grant Stripe Connect"
          >
            <Box sx={style}>
              <Stack spacing={2}>
                <h3>Allow Coach to have a Stripe Connect account</h3>
                <ProBox
                  devPort={devPort}
                  clubId={clubId}
                  setIdPro={setIdPro}
                  boxLabel="Select Coach"
                  disabledPros={stripeConnectUsers}
                  //refresh={players}
                />
                <Grid container spacing={0.5}>
                  <Grid size={{ xs: 5 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid="add-selected-coach-stripe-connect"
                      style={{ width: "100%" }}
                      onClick={() => {
                        console.log("Adding Coach...");
                        changeProStipeConnectAccess(true);
                      }}
                    >
                      ADD
                    </Button>
                  </Grid>
                  <Grid size={{ xs: 5 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: "100%" }}
                      data-testid="camcel-selected-coach-stripe-connect"
                      onClick={() => {
                        setRegisterModal(false);
                      }}
                    >
                      CLOSE
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Modal>
          <Stack spacing={2}>
            <h5> Settings for Club {clubName}</h5>
            <RadioGroup
              row
              aria-labelledby="access-mode-club"
              defaultValue={restrictedAccess}
              name="access-mode-club"
              data-testid={"access-radio-club-" + clubName}
              onChange={(event) => {
                setRestrictedAccess(event.target.value);
                updateApplyButton("privateMode");
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio data-testid={"open-settings-" + clubName} />}
                label="Open"
              />
              <FormControlLabel
                value={1}
                control={<Radio data-testid={"closed-settings-" + clubName} />}
                label="Require manager approval"
              />
            </RadioGroup>
            {!expiredSubscription && !subscriptionEndDate && (
              <SubscriptionTable
                devPort={devPort}
                clubId={clubId}
                newNumberPlayer={null}
                newPricePerPlayer={null}
                subscriptionMode="view"
                setSubscriptionMode={setSubscriptionMode}
                setClubId={setClubId}
              />
            )}
            <ExpiredMembershipBanners
              devPort={devPort}
              clubId={clubId}
              expiredSubscription={expiredSubscription}
              subscriptionEndDate={subscriptionEndDate}
              setSubscriptionEndDate={setSubscriptionEndDate}
            />
            <Typography id="mailing-list" variant="h6">
              Club Mailing List
            </Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 9 }}>
                <TextField
                  id={"player-mailing-list"}
                  label="Players mailing list Name"
                  data-testid={"players-mailing-list"}
                  value={mailingList}
                  onChange={(newValue) => setMailingList(newValue.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid={"save-mailing-list-" + clubName}
                  style={{ width: "100%", height: "100%" }}
                  disabled={dbMailingList === mailingList}
                  onClick={() => {
                    console.log("APPLYING...");
                    changeMailingList();
                  }}
                >
                  <SaveIcon />
                </Button>
              </Grid>
            </Grid>
            <Typography id="signup-deadline" variant="h6">
              Games and Lessons Signup Deadline
            </Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 9 }}>
                <TextField
                  id={"signup-deadline-hours"}
                  label="How many hours before an event should the signup close"
                  data-testid={"signup-deadline-hours"}
                  value={signupDeadline}
                  onChange={(newValue) =>
                    setSignupDeadline(newValue.target.value)
                  }
                  style={{ width: "100%" }}
                  type="number"
                  //slotProps={{
                  //  inputLabel: {
                  //    shrink: true,
                  //  },
                  //}}
                />
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid={"save-signup-deadline-" + clubName}
                  style={{ width: "100%", height: "100%" }}
                  disabled={dbSignupDeadline === signupDeadline}
                  onClick={() => {
                    console.log("APPLYING New Deadline...");
                    changeSignupDeadline();
                  }}
                >
                  <SaveIcon />
                </Button>
              </Grid>
            </Grid>
            <Typography id="waiver-renewal" variant="h6">
              Waiver Renewal Date
            </Typography>
            <DatePicker
              data-testid="waiver-renewal-date"
              //views={['day']}
              value={waiverRenewalDate}
              onChange={(newDate) => {
                console.log(newDate);
                setWaiverRenewalDate(newDate);
                updateApplyButton("waiverRenewal");
              }}
            />
            {waiverLinks.map((waiverLink, index) => {
              return (
                <Stack spacing={1}>
                  <TextField
                    id={"waiver-name-" + index}
                    label="Waiver Name"
                    data-testid={"waiver-name-" + index}
                    value={waiverLink[0]}
                    onChange={(newValue) =>
                      updateWaiverLink(index, newValue.target.value, 0)
                    }
                  />
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id={"waiver-link-" + index}
                      label="Waiver Link"
                      data-testid={"waiver-link-" + index}
                      style={{ width: "90%" }}
                      value={waiverLink[1]}
                      onChange={(newValue) =>
                        updateWaiverLink(index, newValue.target.value, 1)
                      }
                    />
                    <Button
                      variant="contained"
                      color="error"
                      data-testid={"delete-waiver-" + index}
                      onClick={() => {
                        console.log("DEKETE");
                        console.log(index);
                        deleteWaiverLink(index);
                      }}
                    >
                      <DeleteIcon />{" "}
                    </Button>
                  </Stack>
                </Stack>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              data-testid={"add-waiver-link-" + clubName}
              onClick={() => {
                console.log("Adding Waiver Link...");
                const newWaiverLinks = [...waiverLinks];
                console.log(newWaiverLinks);
                newWaiverLinks.push([]);
                setWaiverLinks(newWaiverLinks);
              }}
            >
              Add Link To Waiver
            </Button>
            <Button
              variant="contained"
              color="success"
              data-testid={"apply-restricted-access-" + clubName}
              disabled={!settingsUpdate}
              onClick={() => {
                console.log("APPLYING...");
                applyNewSettings();
              }}
            >
              Apply
            </Button>
            <UserList
              key={clubName}
              devPort={devPort}
              userList={stripeConnectUsers}
              managerMode={-1}
              headerLine="List of coaches allowed to have a Stripe Connect account for
              lesson payment"
              listMode="stripe_connect_access"
              refresher={refresher}
            />
            <Button
              variant="outlined"
              color="primary"
              data-testid={"add-coach-stripe-connect-" + clubName}
              onClick={() => {
                console.log("Adding Coach...");
                setRegisterModal(true);
              }}
            >
              ALLOW COACH TO SETUP STRIPE CONNECT
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default ClubSettingsCard;
