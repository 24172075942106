import {
  Button,
  Card,
  CardContent,
  TextField,
  Stack,
  Alert,
  Snackbar,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import RegistrationModal from "../components/PlayerManagement/RegistrationModal";
import ProBox from "../components/Signups/ProBox";
import DashboardTabs from "../components/ui/DashboardTabs";
import PlayerBox from "../components/Signups/PlayerBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import BannerMessage from "../components/Signups/PriceBanner";
import currencyFormatter from "../components/Lessons/currencyFormatter";
import PurchasePackage from "../components/Lessons/PurchasePackage";
import creditsRetriever from "../components/Lessons/creditsRetriever";
import CreditsBanner from "../components/Lessons/CreditsBanner";
import useClubDeadline from "src/components/Signups/useClubDeadline";
import DeadlineBanner from "src/components/Signups/DeadlineBanner";

// Adds a player's signup for a game
function AddLessonPlayerPage({
  gameId,
  clubId,
  gameDate,
  devPort,
  waiverNotifications,
  managerMode,
  setManagerMode,
  lessonPro,
  setClubId,
  setGameId,
  lessonPrice,
  setLessonPrice,
  betaMode,
}) {
  //const [chukkerRequestedTemporary, setChukkerRequestedTemporary] = useState(2);
  const [playerRating, setPlayerRating] = useState(-2);
  const [playerClubRating, setPlayerClubRating] = useState(-2);
  const [playerUSPARating, setPlayerUSPARating] = useState(-2);
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [totalCredits, setTotalCredits] = useState();
  const [packageReference, setPackageReference] = useState();
  //useRefresh(clubId, devPort);

  const [registerModal, setRegisterModal] = useState(false);
  //const [idMapping, setIdMapping] = useState();
  const [idPlayer, setIdPlayer] = useState();
  //const [idPro, setIdPro] = useState(-1);
  //const [loadingClubPlayers, setLoadinClubPLayers] = useState(false);
  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get("session_id");
  const [loadingSignups, setLoadingSignups] = useState(false);
  const [signupNotes, setSignupNotes] = useState();
  const [registerPlayerAfterStripe, setRegisterPlayerAfterStripe] =
    useState(false);

  const [pricingValue, setPricingValue] = useState();
  const [pricingCurrency, setPricingCurrency] = useState();
  const [validCredits, setValidCredits] = useState();
  const [lessonPackagePurchase, setLessonPackagePurchase] = useState([]);
  const [stripeRef, setStripeRef] = useState()

  const { isDeadlinePassed } = useClubDeadline(managerMode, devPort, clubId, gameDate);

  let navigate = useNavigate();
  useEffect(() => {
    creditsRetriever(
      managerMode,
      devPort,
      clubId,
      gameId,
      "lesson_pricing",
      setValidCredits
    );
  }, [managerMode, devPort, clubId, gameId]);

  useEffect(() => {
    if (lessonPrice) {
      const fetchPriceValue =
        devPort + "get-lesson-pricing-value/" + lessonPrice + "/lesson_pricing";
      fetch(fetchPriceValue)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Actual Price");
          console.log(data);
          setPricingValue(data[0][0] / 100.0);
          setPricingCurrency(data[0][1]);
        });
    }
  }, [lessonPrice, devPort]);
  useEffect(() => {
    if (stripeSessionId) {
      const stripeFetch =
        devPort + "retrieve-stripe-payment/" + stripeSessionId;
      console.log(stripeFetch);
      fetch(stripeFetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            console.log(data);
            console.log(data["clubId"]);
            setClubId(data[0]["clubId"]);
            setGameId(data[0]["gameId"]);
            setIdPlayer(data[0]["playerId"]);
            setSignupNotes(data[0]["signupNotes"]);
            setLessonPrice(data[0]["lessonPriceId"]);
            setManagerMode(parseInt(data[0]["uid"]));
            setStripeRef(data[0]["stripe_transaction_reference"])
            if (data[0]["package_reference"]) {
              setTotalCredits(data[0]["total_credits"]);
              setPackageReference(data[0]["package_reference"]);
            } else {
              setTotalCredits();
              setPackageReference("");
            }
            setRegisterPlayerAfterStripe(true);
          }
        });
    }
  }, [
    devPort,
    stripeSessionId,
    setClubId,
    setGameId,
    setLessonPrice,
    setManagerMode,
  ]);

  function refresh() {
    //TODO HANDLE THAT
    //loadPlayers();
    //getPlayerList();
  }
  const registerPlayer = useCallback(
    (lessonPaid) => {
      //TODO Register player
      //console.log(JSON.stringify(idMapping));
      console.log(idPlayer);

      const saveSignup = devPort + "post_lesson_signup";
      console.log(saveSignup);
      console.log(signupNotes);

      const signupSave = {
        player_id: idPlayer,
        club_id: clubId,
        lesson_id: gameId,
        signup_notes: signupNotes,
        lesson_paid: lessonPaid,
        total_credits: totalCredits,
        package_reference: packageReference,
        stripe_transaction_reference: stripeRef
      };
      console.log(signupSave);
      if (!idPlayer) {
        window.alert("Select a player");
      } else {
        fetch(saveSignup, {
          method: "POST",
          body: JSON.stringify(signupSave),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            console.log(data.results.message);
            setAlertText(data.results.message);
            if (
              data.results.message === "Student has been signed up" ||
              data.results.message ===
                "Lesson might have already been processed by Stripe"
            ) {
              setAlertSeverity("success");
            } else {
              setAlertSeverity("secondary");
            }
            setAlertMessage(true);
            setLoadingSignups(true);
            navigate("/lessonsignup");
          });
      }
      //setLoadingSignups(!loadingSignups);
    },
    [clubId, devPort, gameId, totalCredits, packageReference, idPlayer, signupNotes, stripeRef, navigate]
  );

  const registerPlayerWithCredit = useCallback(
    (packageUsed) => {
      //TODO Register player
      //console.log(JSON.stringify(idMapping));
      console.log(idPlayer);

      const saveSignup = devPort + "register_lesson_with_credit";
      console.log(saveSignup);
      console.log(signupNotes);

      const signupSave = {
        player_id: idPlayer,
        club_id: clubId,
        lesson_id: gameId,
        signup_notes: signupNotes,
        package_reference: packageUsed,
      };
      console.log(signupSave);
      if (!idPlayer) {
        window.alert("Select a player");
      } else {
        fetch(saveSignup, {
          method: "POST",
          body: JSON.stringify(signupSave),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            console.log(data.results.message);
            setAlertText(data.results.message);
            if (data.results.message.match("Failure")) {
              setAlertSeverity("secondary");
            } else {
              setAlertSeverity("success");
            }
            setAlertMessage(true);
            setLoadingSignups(true);
            navigate("/lessonsignup");
          });
      }
      //setLoadingSignups(!loadingSignups);
    },
    [clubId, devPort, gameId, idPlayer, signupNotes, navigate]
  );

  useEffect(() => {
    if (registerPlayerAfterStripe) {
      registerPlayer(true);
    }
  }, [registerPlayerAfterStripe, registerPlayer]);

  const [alterMessage, setAlertMessage] = useState(false);
  const [alertText, setAlertText] = useState();
  const [alertSeverity, setAlertSeverity] = useState("success");
  let currentPage = "/addlessonplayer";
  let registerText = "New Player";
  //const [players, setPlayers] = useState();

  if (managerMode !== -1) {
    //currentPage = "/playerregister";
    registerText = "";
  }

  console.log(window.location.href.split("#")[0]);
  function createCheckoutLesson() {
    //setLoadingPricing(true);
    if (!idPlayer) {
      window.alert("Select a player from the dropdown menu");
    } else {
      fetch(devPort + "create-checkout-lesson", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playerId: idPlayer,
          uid: managerMode,
          clubId: clubId,
          gameId: gameId,
          signupNotes: signupNotes,
          //email: userEmail,
          lesson_price_id: lessonPrice,
          //manager_connect_id: "acct_1O6i6CPtgX9JR4K2",
          //lesson_price: 75.0,
          website: window.location.href.split("#")[0], //"http://localhost:3000"
        }),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          window.location.href = data.url;
        });
    }
  }

  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} devPort={devPort}>
      <DashboardTabs
        currentPage={currentPage}
        managerMode={managerMode}
        gameMode="lesson"
      />
      <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert severity={alertSeverity} data-testid="player-has-been-added">
          {alertText}
        </Alert>
      </Snackbar>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1>
              {" "}
              Register {registerText} for Lesson on {gameDate}
            </h1>
            <DeadlineBanner managerMode={managerMode} clubId={clubId} gameDate={gameDate} devPort={devPort} />
            <Stack spacing={3}>
              {lessonPrice && pricingValue && !validCredits && (
                <Stack data-testid="pricing-banner">
                  <BannerMessage
                    message={
                      "Lesson Price has been set by the manager to " +
                      currencyFormatter(pricingValue, pricingCurrency)
                    }
                    color={"primary"}
                  />
                </Stack>
                //<Typography>Lesson Price {pricingValue}</Typography>
              )}
              {validCredits && <CreditsBanner validCredits={validCredits} />}
              {!stripeSessionId && (
                <PlayerBox
                  devPort={devPort}
                  clubId={clubId}
                  gameId={gameId}
                  setIdPlayer={setIdPlayer}
                  refresh={loadingSignups}
                  managerMode={managerMode}
                  playerFixedId={managerMode !== -1 ? managerMode : null}
                  mode="lesson"
                />
              )}
              <ProBox
                devPort={devPort}
                clubId={clubId}
                //setIdPro={setIdPro}
                proRequest={lessonPro}
                //refresh={players}
                disabled={true}
                boxLabel="Taking a lesson with"
              />
              <div>Note</div>
              <TextField
                id="outlined-multiline-flexible"
                label="Add requests or notes here"
                data-testid="signup-notes"
                multiline
                maxRows={3}
                onChange={(input) => {
                  setSignupNotes(input.target.value);
                }}
              />
              {(((!lessonPrice || !pricingValue) &&
                !validCredits &&
                (!lessonPackagePurchase ||
                  lessonPackagePurchase.length === 0)) ||
                managerMode === -1) && (
                <Button
                  variant="contained"
                  data-testid="register-player"
                  color="success"
                  disabled={!idPlayer || isDeadlinePassed}
                  onClick={() => registerPlayer(false)}
                >
                  {" "}
                  Register {managerMode === -1 ? "Player" : " and Pay Later"}
                </Button>
              )}
              {lessonPrice &&
                pricingValue &&
                managerMode !== -1 &&
                !validCredits && (
                  <Button
                    variant="contained"
                    data-testid="register-player-pay"
                    color="success"
                    disabled={!idPlayer || isDeadlinePassed}
                    onClick={createCheckoutLesson}
                  >
                    Register and Pay Now
                  </Button>
                )}
              {managerMode !== -1 && validCredits && (
                <Stack data-testid="credits-apply">
                  {Object.keys(validCredits).map((creditRef) => {
                    return (
                      <Button
                        variant="contained"
                        key={"register-player-credit-" + creditRef}
                        data-testid={"register-player-credit-" + creditRef}
                        color="success"
                        disabled={!idPlayer || isDeadlinePassed}
                        onClick={() => registerPlayerWithCredit(creditRef)}
                      >
                        {"Register using credit from " + creditRef + " package"}
                      </Button>
                    );
                  })}
                </Stack>
              )}
              {idPlayer && !validCredits && !isDeadlinePassed && (
                <PurchasePackage
                  devPort={devPort}
                  clubId={clubId}
                  gameId={gameId}
                  managerMode={managerMode}
                  idPlayer={idPlayer}
                  signupNotes={signupNotes}
                  lessonPackagePurchase={lessonPackagePurchase}
                  setLessonPackagePurchase={setLessonPackagePurchase}
                  packageType="lesson"
                />
              )}
              {managerMode === -1 && false && (
                <div>
                  {" "}
                  New player is not part of the club? Register a temporary
                  player below
                </div>
              )}
              {managerMode === -1 && false && (
                <Button
                  variant="contained"
                  data-testid="register-temporary-player"
                  color="success"
                  onClick={() => {
                    setRegisterModal(true);
                  }}
                >
                  {" "}
                  Temporary Player
                </Button>
              )}
            </Stack>
          </Stack>
          <RegistrationModal
            managerMode={managerMode}
            devPort={devPort}
            editMode={false}
            clubId={clubId}
            gameId={gameId}
            playerId={0}
            playerModal={registerModal}
            setPlayerModal={setRegisterModal}
            baseEdit={true}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            rating={playerRating}
            setRating={setPlayerRating}
            playerClubRating={playerClubRating}
            setPlayerClubRating={setPlayerClubRating}
            playerUSPARating={playerUSPARating}
            setPlayerUSPARating={setPlayerUSPARating}
            //chukkerNumber={chukkerRequestedTemporary}
            //setChukkerNumber={setChukkerRequestedTemporary}
            refresh={refresh}
          />
        </CardContent>
      </Card>
    </Layout>
  );
}

export default AddLessonPlayerPage;
